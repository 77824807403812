<template>
	<div>

		<EditPopup :data="data_popup" :show_dialog="popup" :close="close_method"/>
		<v-dialog v-model="dialog" fullscreen scrollable :retain-focus="false" hide-overlay>

			<v-card class="wrapper">
				<div class="headbar">
<!--					<div class="text-center">-->
<!--						<v-dialog v-model="authErrorPopup" width="500">-->
<!--							<v-card class="wrapper">-->
<!--								<div>-->
<!--									<Icon name="warning"/>-->
<!--								</div>-->
<!--								<div class="popup_title">-->
<!--									Токен аккаунта устарел, нужно заново авторизироватся!-->
<!--								</div>-->
<!--								<div class="my_btn add_modal" @click="deleteNumber()">Авторизоваться</div>-->
<!--							</v-card>-->
<!--						</v-dialog>-->
<!--					</div>-->
					<div class="back" @click="close">
						<Icon name="left_blue"/>
						<span>Назад</span>
					</div>
					{{ data.name }}
				</div>
				<div class='render_box'>
					<div id='render' v-show="load"></div>
					<div class='load' v-show="!load">
						Загрузка...
					</div>
					<div class="control" :class="{open:control_open}">
						<div class="control_buttoms">
							<div class="item" @click="add_item(menu_item)" :class="menu_item.obj" v-for="(menu_item, index) in action_items" :key="index">
								<div class="icon_wrapper">
									<Icon :name="menu_item.icon"/>
								</div>
								{{ menu_item.title }}
								<v-divider v-if="menu_item.obj =='divider'"></v-divider>
							</div>
						</div>
						<div class="collapse" @click="control_open = !control_open">
							<Icon :name="[control_open ? 'left_arr': 'right_arr']"/>
						</div>
					</div>
					<div class="buttons">
						<div class="btn_save btn-close" @click="save(true)">Сохранить и закрыть</div>
<!--						<div class="btn_save" @click="save()">Сохранить</div>-->
						<v-tooltip
							v-model="showSave"
							top
							color="success"
						>
							<template v-slot:activator="{ attrs }">
								<div class="btn_save" @click="save()" v-bind="attrs" >Сохранить</div>
							</template>
							<span>Сохранено!</span>
						</v-tooltip>
            <div class="btn_test" @click="export_scenario()">Экспорт сценария</div>
						<div class="btn_test" @click="test_call()">Тестировать сценарий</div>
						<div class="import-file-container">
							<label for="fileInput" class="btn_test">Импортировать файл</label>
			<input id="fileInput" class="btn_test" type="file" ref="fileInput" @change="importFile" style="display: none;">
						</div>
					</div>
					<div class="scale_wrapper">
						<ScaleMy/>
					</div>
				</div>
				<TestCall :show_dialog="testCall_popup" :data="call_data" :close="close_method"/>

			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import EditPopup from './EditPopup.vue'
import TestCall from './TestCall.vue'
import Icon from '../../SetIcon.vue'
import ScaleMy from '../../ScaleMy.vue'

export default {
	components: {
		EditPopup, Icon, ScaleMy, TestCall
	},
	props: ['show_dialog', 'close', 'lib', 'data', 'id', 'callback_refresh'],
	methods: {
		importFile(event) {
      this.importedFile = event.target.files[0];
	if (this.importedFile) {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          try {
			window.start_edit()
            const importedData = JSON.parse(e.target.result);
			this.$http.post("/script/import", {
				id: this.id,
				data: {
					data: importedData
				}
			}, (data) => {
				if(data){
		alert('Данные успешно импортированы, страница будет перезагружена!');
        window.location.reload();
				}
				else {
					alert('Вы выбрали неподходящий файл для импорта');
				}
			})
          } catch (error) {
            console.error('Ошибка при чтении файла:', error);
			alert('Произошла ошибка при чтении файла');
          }
        };
		fileReader.onerror = () => {
      alert('Произошла ошибка при чтении файла');
    };
        fileReader.readAsText(this.importedFile);
      } else {
        console.error('Файл не выбран');
		alert('Файл не выбран');
      }
    },
		robot_edit(data) {
			window.start_edit()
			this.data_popup = data
			let _this = this
			setTimeout(function () {
				_this.popup = true
			}, 50)
		},
	// 	importData() {
    // },
    export_scenario(){
      window.start_edit()
      this.call_data = window.save_scenario({})

	const blob = new Blob([JSON.stringify(this.call_data)], { type: 'application/json' });
	const url = window.URL.createObjectURL(blob);
	const link = document.createElement('a');
  link.href = url;
  link.download = 'exported_data.json';

  document.body.appendChild(link);
  link.click();

  window.URL.revokeObjectURL(url);
document.body.removeChild(link);
this.$http.post("/script/export", {
    data: this.call_data
}, (data) => {
    window.open(data.link, '_blank');
});
    },
		test_call() {
			window.start_edit()
			this.call_data = window.save_scenario({})
			this.testCall_popup = true
		},
		close_method() {
			window.stop_edit()
			this.popup = false
			this.testCall_popup = false
			this.blacklist_popup = false
		},
		cb_onload() {
			this.load = true
		},
		add_item(item) {
			window.add_item(item.action)
		},
		save(close = false) {
			let save = window.save_scenario({})
			this.$http.post("/script/save", {
				time: new Date().toLocaleString('en-US', { timeZoneName: 'short' }),
				id: this.id,
				save: {
					data: save
				}
			}, (data) => {
				//this.callback_refresh()
				//this.close()
				//console.log(data) // auth_error
				if(data === 'auth_error'){
					location.reload();
					//this.authErrorPopup = true
				} else {
					if (close){
						this.callback_refresh()
						this.close()
						return data
					} else {
						this.showSave = true
						setTimeout(() => this.showSave = false, 3000)
						return data
					}
				}
			})
		},
		saveInterval(){
			setTimeout(() => {
				if (this.dialog){
					this.save()
				}
				this.saveInterval()
			}, 600000)
		}
	},
	watch: {
		show_dialog: function (val) {
			this.dialog = val
			if (val) {
				this.load = false
				let cbs = {
					"onload": this.cb_onload,
					"robot_edit": this.robot_edit,
				}

				window.init_scenario(this.data.data, this.lib, cbs)
			} else {
				window.clear_scenario()
			}
		},
	},
	data() {
		return {
			popup: false,
			testCall_popup: false,
			control_open: true,
			data_popup: {},
			call_data: {},
			load: false,
			dialog2: false,
			action_items: [
				{title: 'Реплика робота', action: 'robot', icon: 'replic'},
				{title: 'Ответы клиента', action: 'answer', icon: 'answer'},
				{title: 'Перевести звонок', action: 'callcenter', icon: 'headphones'},
				{obj: 'divider'},
				{title: 'Отправить смс', action: 'send_sms', icon: 'sms'},
				{title: 'Цель', action: 'target', icon: 'target'},
				{title: 'Переадресация на блок', action: 'forwarding', icon: 'forwarding'},
				{title: 'Отправить сообщение в телеграм', action: 'send_tg', icon: 'tg'},
				{title: 'Отправить http запрос', action: 'send_http', icon: 'code'},
				{title: 'Интеграция amoCRM', action: 'amocrm', icon: 'integration'},
				{title: 'Интеграция Bitrix24', action: 'bitrix', icon: 'integration'},
				{title: 'Черный список', action: 'blacklist', icon: 'blacklist'},
			],
			dialog: false,
			showSave: false,
			authErrorPopup: false
		}
	},
	mounted() {
		this.saveInterval()
	},
}
</script>
<style>

.add_modal {
	border: none;
	width: 100%;
	padding: 12px 20px 12px 22px;
	background: #FEE600 !important;
	border-radius: 8px;
	font-weight: 600;
	font-size: 15px;
	line-height: 120%;
	justify-content: center;
	color: #2B2D33;
	cursor: pointer;
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	font-style: normal;
}
.popup_title{
	font-weight: 800;
	font-size: 24px;
	line-height: 130%;
	text-align: center;
	color: #2B2D33;
	margin: 16px 0 19px 0;
}
.divider .icon_wrapper {
	display: none;
}

.btn_save {
	width: auto;
	border-radius: 8px;
	background: #FEE600;
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	cursor: pointer;
	margin-bottom: 0;
	padding: 13px 24px;
}

.btn-close {
	margin-right: 16px;
}

.btn_test {
	font-style: normal;
	padding: 13px 24px;
	margin-left: 16px;
	font-weight: 600;
	font-size: 17px;
	line-height: 120%;
	color: #2B2D33;
	background: #FFFFFF;
	border: 1px solid #FEE600;
	border-radius: 8px;
	white-space: nowrap;
	cursor: pointer;
}

.scale_wrapper {
	position: absolute;
	bottom: 30px;
	right: 30px;
}

.icon_wrapper {
	width: 28px;
	height: 28px;
	padding: 4px;
	background: #F8F8F8;
	border-radius: 4px;
	margin-right: 6px;
	margin-left: 12px;
}

.render_box {
	position: relative;
}

.load {
	width: 100%;
	height: calc(100vh - 48px);
	overflow: hidden;
	line-height: calc(100vh - 48px);
}

#render {
	width: 100%;
	height: calc(100vh - 48px);
	overflow: hidden;

}

.buttons {
	display: flex;
	position: absolute;
	bottom: 16px;
	left: 20px;
}

.item {
	display: none;
}

.control.open .item {
	text-align: left;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 9px 12px;
	margin-top: 12px;
	margin-bottom: 12px;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 120%;
	color: #2B2D33;
	padding-right: 40px;
}

.item:hover {
	background: #F8F8F8;
}

.divider {
	padding: 0;
}

.divider:hover {
	cursor: initial;
	background: #fff;
}

.control {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	padding: 4px 0;
	margin-top: 150px;
	background: #FFFFFF;
	border-radius: 6px 0 0 6px;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.1);
}

.control.open {
	/*width: 250px;*/
	/*left: 15px;*/

	/*width: 250px;*/
	/*left: 15px;*/
	/*max-height: 65%;*/

	width: 250px;
	left: 15px;
	max-height: 60vh;
	height: available;
	height: -webkit-fill-available;
}

.control_buttoms {
	/*width: 250px;*/
	/*left: 15px;*/
	/*max-height: 65%;*/
	width: 100%;
	/*overflow-y: scroll;*/
	max-height: 100%;
	overflow-y: auto;
	position: absolute;
	top: 0;
	bottom: 0;
}

.collapse {
	position: absolute;
	right: -30px;
	/*right: 0px;*/
	width: 30px;
	top: 0;
	background: #fff;
	outline: 1px solid #e2e2e2;
	border-radius: 0 6px 6px 0;
	/*height: 100%;*/
	display: flex;
	align-items: center;
	justify-content: center;
	/*height: 590px;*/
	height: 60vh;
}

.headbar {
	text-align: center;
	height: 48px;
	padding-left: 20px;
	padding-right: 20px;
	font-weight: 700;
	font-size: 17px;
	line-height: 48px;
	box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.06), 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.headbar .back {
	cursor: pointer;
	display: flex;
	align-items: center;
	margin-left: 12px;
	float: left;
	font-weight: 500;
	font-size: 15px;
	color: #00809C;
}

.headbar .back span {
	margin-left: 12px;
}

.import-file-container {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

@media (max-width: 600px) {
	.scale_wrapper {
		top: 30px;
		left: 30px;
		bottom: initial;
		right: initial;
	}
}

@media (max-width: 450px) {
	.buttons {
		flex-direction: column;
	}

	.btn_test {
		margin-left: 0;
		margin-top: 15px;
	}
}
</style>
