<template>
  <div class="sms">
    <div class="sms_alert">
      <Icon name="warning" class="alert_icon" />
      <span>Для работы смс-сообщений требуется подключенная интеграция с SMSLeads</span>
    </div>
    <v-toolbar flat>
      <template v-slot:extension>
        <v-tabs v-model="data_local.type" centered>
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tab active-class="active_tab" v-for="item,i in tabs" :key="i" >
            {{ item }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    
    <v-tabs-items v-model="data_local.type">
      <v-tab-item v-for="item,i in tabs" :key="i" >
        <v-card v-for="tab_item,j in tab_items" :key="j" flat>
          <div class="item_tab" v-if="item == tab_item.vs">
            <div class="subtitle" v-if="data_local.type==1">Номер телефона</div>
            <div class="my_input" :class="numState" v-if="data_local.type==1">
              <v-text-field height="40" :placeholder="data_local.phone" disabled filled></v-text-field>
              <div class="icon_wrapper" @click="switch_lock">
                <Icon :name="icon" />
              </div>
            </div>
            <div class="btn_unlock" v-if="data_local.type==1">Разблокировать больше номеров</div>
            <h3>Текст сообщения</h3>
            <v-textarea 
              class="my_textarea" 
              name="input-7-1" 
              auto-grow 
              @input="input" 
              v-model="data_local.sms_text"
              required
              maxlength="65"
              placeholder="Вы успешно записаны к психологу на 13 число этого месяца"
            ></v-textarea>
            <div class="row">
              <div class="num_symbols">Символов осталось: {{symbols()}}</div>
              <div v-if="data_local.type==1" class="paste" @click="paste">Вставить номер клиента</div>
            </div>
          </div>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Icon from '../../../SetIcon.vue'
export default {
  props: ['data','edit_callback'],
  components: {
    Icon
  },
  methods:{
    paste()
    {
      this.data_local.sms_text=this.data_local.sms_text+"{phone}"
    },
    symbols(){
      return this.max_symbols - this.data_local.sms_text.length
    },
    input()
    {
      this.edit_callback(this.data_local)
      return true
    },
    switch_lock(){
      if(this.icon == 'lock'){
        this.numState = 'unlocked'
        this.icon = 'unlock'
      } else {
        this.numState = 'locked'
        this.icon = 'lock'
      }
    }
  },
  watch: {
    data: function (val) { 
      if (!val.phone || val.phone==0)
      {
      val.phone=this.$store.getters['user/getName']  
      }
      this.data_local=val
    }
  },
  mounted()
  {
    this.data_local=this.data
    if (!this.data.phone || this.data.phone==0)
    {
    this.data_local.phone=this.$store.getters['user/getName']  
    }
    
  },
  data(){
    return {
      numState: 'locked',
      icon: 'lock',
      max_symbols: 65,
      model:0,
      sms_text:'',
      items: ["Алиса", "Боб", "Яна"],
      data_local:{
        tab:0,
        phone:0,
        sms_text:''
      },
      tab: null,
      tabs: ['Клиенту', 'На другой номер'],
      tab_items: [
        { title: 'Текст сообщения 1', vs: 'Клиенту'},
        { title: 'Текст сообщения 2', vs: 'На другой номер'}
      ]
    }
  }
}
</script>
<style scoped>
.sms_alert {
  background-color: #FFFBEA;
  color: #FFB74D;
  padding: 10px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.icon_wrapper{
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
  width: 12px;
  height: 15px;
  padding: 0;
  background: none;
}
.active_tab{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #2B2D33;
}
.btn_unlock{
  font-style: normal;
  font-weight: 500;
  text-align: left;
  cursor: pointer;
  margin-bottom: 19px;
  font-size: 15px;
  line-height: 120%;
  color: #00809C;
}
.row{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0 0 0!important;
}
.my_input{
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #808185;
  position: relative;
}

.subtitle{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #404247;
  text-align: left;
  margin-bottom: 6px;
}
.modal_header{
  display: flex;
  align-self: flex-end;
}
.item_tab{
  margin: 32px auto 0 auto;
  max-width: 600px;
}
.modal_body{
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.save{
  padding: 10px 20px 12px;
  background: #FEE600;
  border-radius: 8px;
  margin: 16px 0 0 0;
}
.paste{
  padding: 9px 20px 10px;
  background: #FFFFFF;
  border: 1px solid #FEE600;
  border-radius: 8px;
  margin: 0;
  cursor: pointer;
  max-width: 235px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  color: #2B2D33;
}
.modal_title{
margin-top: 32px;
margin-bottom: 32px;
}
h3{
  margin-bottom: 12px;
  text-align: left;
}
.close{
  margin-right: 52px;
  margin-top: 20px;
}
</style>