<template>
  <div v-if="!this.$store.getters['user/getUserFields'].is_admin">Вы не администратор, авторизируйтесь как администратор!</div>
  <div  v-else style="width: 100%; padding: 10px">
    <h3>Количество активных обзвонов: {{ list.length }}</h3>
    <div style="width: 100%">
      <h3>Список активных обзвонов</h3>
      <div style="">
        <div style="display: flex;  width: 100%; flex-direction: row">
          <div style="width: 420px; border: 1px solid silver"><b>Идентификатор обзвона</b></div>
          <div style="width: 500px; border: 1px solid silver"><b>Название обзвона </b></div>
          <div style="width: 200px; border: 1px solid silver"><b>Пользователь </b></div>
          <div style="width: 180px; border: 1px solid silver"></div>
          <div style="width: 180px; border: 1px solid silver"></div>
        </div>

        <div style=" width: 100%; ">
          <div v-for="item in list" :key="item.key" style=" width: 100%; display: flex; flex-direction: row; height: 50px">
            <div
              style="width: 420px; border-left: 1px solid silver; border-right: 1px solid silver; border-bottom: 1px solid silver; text-align: left; padding: 10px">
              {{
              item.key }}</div>
            <div
              style="width: 500px; border-left: 1px solid silver; border-right: 1px solid silver; border-bottom: 1px solid silver; text-align: left; padding: 10px">
              {{
  item.name }}</div>
            <div
              style="width: 200px; border-left: 1px solid silver; border-right: 1px solid silver; border-bottom: 1px solid silver; text-align: left; padding: 10px">
              {{
              item.user }}</div>
            <div style="width: 180px; border-left: 1px solid silver; border-right: 1px solid silver; border-bottom: 1px solid silver;">
              <button style="background: #f31510; padding: 8px; margin: 5px; opacity: 90%; border-radius: 5px; color: white" class="hvr"
                @click="stop(item.key, item.user)">Остановить</button>
            </div>
            <div style="width: 180px; border-left: 1px solid silver; border-right: 1px solid silver; border-bottom: 1px solid silver;">
              <button style="background: orange; padding: 8px; margin: 5px; opacity: 90%; border-radius: 5px; color: white" class="hvr"
                @click="success(item.key, item.user)">Завершить</button>
            </div>

          </div>
        </div>

      </div>

    </div>

  </div>

</template>
<script>
export default {
  data () {
    return {
      count: 0,
      list: []
    }
  },
  methods: {
    getAll(){
      this.$http.post("/list_calls/all", {}, result => {
        this.list = result

      })
    },
    
    stop (call_id, user) {
      this.$http.post("/list_calls/stop", {call_id, user}, () => {
        this.getAll()        
      })
    },
    
    
    success (call_id, user) {
      this.$http.post("/list_calls/success", { call_id, user }, () => {
        this.getAll()        
      })
    }
  },
  mounted () {
    this.getAll()        


  }
}
</script>

<style>
.hvr:hover {
  background: #bc4747 !important;
  color: black !important
}
</style>