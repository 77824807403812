<template>
  <div v-if="!this.$store.getters['user/getUserFields'].is_admin">Вы не администратор, авторизируйтесь как администратор!</div>
  <div  v-else class="container">
    <div class="filters">
      <input type="datetime-local" v-model="startDate">
      <input type="datetime-local" v-model="endDate">
      <button @click="fetchData">Выбрать период</button>
      
      <select v-model="account" @change="filterDataOnAccount" multiple>
        <option disabled selected></option>
        <option v-for="accountInfo in accountcheckOptions" :value="accountInfo" :key="accountInfo">{{ accountInfo }}</option>
      </select>
      <button @click="clearAccountFilter">Очистить аккаунт</button>
      
      <select v-model="channel" @change="filterDataOnChannel" multiple>
        <option disabled selected></option>
        <option v-for="channelName in channelOptions" :value="channelName" :key="channelName">{{ channelName }}</option>
      </select>
      <button @click="clearChannelFilter">Очистить транк</button>
      
      <input type="text" v-model="phoneB" placeholder="Телефон B" @keyup.enter="fetchDataWithPhoneB">
      <button @click="resetFilters">Сбросить фильтры</button>
    </div>

    <div class="windows">
      <div class="window">
        <div class="icon"><i class="fas fa-clock"></i></div>
        <h3>Секунды</h3>
        <p>{{ seconds }}</p>
      </div>

      <div class="window">
        <div class="icon"><i class="fas fa-hourglass-half"></i></div>
        <h3>Минуты</h3>
        <p>{{ minutes }}</p>
      </div>

      <div class="window">
        <div class="icon"><i class="fas fa-ruble-sign"></i></div>
        <h3>Сумма</h3>
        <p>{{ sum }} ₽</p>
      </div>
    </div>

    <div class="tables">
      <div class="table-container">
        <p>Статистика по аккаунтам, которые звонили за выбранный вами период</p>
        <button @click="exportAccountStatsToExcel">Экспорт Статистики по аккаунтам в Excel</button>
        <table>
          <thead>
            <tr>
              <th>Аккаунт</th>
              <th>Транк</th>
              <th>Секунды</th>
              <th>Минуты</th>
              <th>Цена</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in accountOptions" :key="index">
              <td>{{ item.user }}</td>
              <td>{{ item.channel }}</td>
              <td>{{ item.total_seconds }}</td>
              <td>{{ item.totalMinutes }}</td>
              <td>{{ item.total_price.toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="table-container">
        <p>Аналитика транков</p>
        <button @click="exportChannelAnalyticsToExcel">Экспорт Аналитики транков в Excel</button>
        <table>
          <thead>
            <tr>
              <th>Транк</th>
              <th>Общее количество вызовов</th>
              <th>Отвечено</th>
              <th>Процент дозвона</th>
              <th>Кол-во секунд</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tableData" :key="item.channel">
              <td>{{ item.channel }}</td>
              <td>{{ item.total_records }}</td>
              <td>{{ item.records_with_seconds_greater_than_1 }}</td>
              <td>{{ item.percentage_with_seconds_greater_than_1.toFixed(2) }}%</td>
              <td>{{ item.total_seconds }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="table-container">
        <p>Звонки выбранного вами пользователя</p>
        <button @click="exportUserCallsToExcel">Экспорт Звонков пользователя в Excel</button>
        <table>
          <thead>
            <tr>
              <th>Пользователь</th>
              <th>Дата звонка</th>
              <th>phoneB</th>
              <th>Транк</th>
              <th>Продолжительность вызова</th>
              <th>amdCause</th>
              <th>Ссылка на запись</th>
              <th>call_name</th>
              <th>amdstatus</th>
              <th>ami_error</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in userCalls" :key="item.id">
              <td>{{ item.user }}</td>
              <td>{{ item.created_at }}</td>
              <td>{{ item.phoneB }}</td>
              <td>{{ item.channel }}</td>
              <td>{{ item.seconds }}</td>
              <td>{{ item.asterisk_box }}</td>
              <td>{{ item.record }}</td>
              <td>{{ item.call_name }}</td>
              <td>{{ item.status }}</td>
              <td>{{ item.text }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="pagination">
      <button @click="previousPage" :disabled="offset === 1">Предыдущая</button>
      <button @click="nextPage" :disabled="userCalls.length < limit">Следующая</button>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx';
export default {
  name: 'StatisticsTable',
  data() {
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date();
    endDate.setHours(23, 59, 0, 0);
    return {
      startDate: `${startDate.toISOString().slice(0, 10)}T00:00`,
      endDate: `${endDate.toISOString().slice(0, 10)}T23:59`,
      account: '',
      channel: '',
      phoneB: '',
      seconds: '',
      minutes: '',
      sum: '',
      tableData: [],
      accountOptions: [],
      accountcheckOptions: [],
      userCalls: [],
      userCallsFull:[],
      channelOptions: [],
      uniqueAccountsData: {},
      offset: 0,
      limit: 50,
    };
  },
  methods: {
    previousPage() {
  if (this.offset > 1) {
    this.offset - 50;
    this.fetchDataWithPhoneB();
  }
},

nextPage() {
  if (this.userCalls.length === this.limit) {
    this.offset + 50;
    this.fetchDataWithPhoneB();
  }
},
    async fetchDataWithPhoneB() {
    try {
      this.$http.post('/statistics/phoneB', { phoneB: this.phoneB, offset: this.offset, limit: this.limit }, result => {
        this.userCalls = result
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  },
async fetchData() {
  try {
  this.$http.post('/statistics/date', { startDate: this.startDate, endDate: this.endDate }, result => {
      this.seconds = result.summary[0].totalSeconds;
      this.minutes = result.summary[0].totalMinutes;
      this.sum = result.summary[0].totalPrice;
      this.tableData = result.channelAnalytics;
      this.accountOptions = result.userChannelDetails;
      this.channelOptions = result.channelAnalytics.map(item => item.channel);
      this.accountcheckOptions = [...new Set(result.userChannelDetails.map(item => item.user))];

  });
  } catch (error) {
    console.error('Error fetching data:', error);
  }
},
filterDataOnAccount() {
  this.$http.post('/statistics/account', { account: this.account, startDate: this.startDate, endDate: this.endDate, channel: this.channel }, result => {
    this.accountOptions = result.result;
    this.tableData = result.result1
    this.seconds = result.result2[0].totalSeconds;
      this.minutes = result.result2[0].totalMinutes;
      this.sum = result.result2[0].totalPrice;
      });
},
filterDataOnChannel() {
  this.$http.post('/statistics/channel', { channel: this.channel, startDate: this.startDate, endDate: this.endDate, account: this.account, }, result => {
    this.tableData = result.result;
    this.accountOptions = result.result1;
    this.seconds = result.result2[0].totalSeconds;
    this.minutes = result.result2[0].totalMinutes;
    this.sum = result.result2[0].totalPrice;
      });
},
exportChannelAnalyticsToExcel() {
    const ws = XLSX.utils.json_to_sheet(this.tableData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Аналитика транков');

    XLSX.writeFile(wb, 'channel_analytics.xlsx');
  },
 exportUserCallsToExcel() {
  const params = {
        phoneB: this.phoneB,
    };

    if (this.startDate) {
        params.startDate = this.startDate;
    }
    if (this.endDate) {
        params.endDate = this.endDate;
    }
    if (this.account) {
        params.account = this.account;
    }
    if (this.channel) {
        params.channel = this.channel;
    }
        this.$http.post('/statistics/phoneBFull', params , result => {
        this.userCallsFull = result
        const ws = XLSX.utils.json_to_sheet(this.userCallsFull);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Звонки пользователя');  
    
        XLSX.writeFile(wb, 'user_calls.xlsx');
      });
  },
  exportAccountStatsToExcel() {
    const ws = XLSX.utils.json_to_sheet(this.accountOptions);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Статистика по аккаунтам');

    XLSX.writeFile(wb, 'account_statistics.xlsx');
  },
  resetFilters() {
      this.startDate = '';
      this.endDate = '';
      this.account = '';
      this.channel = '';
      this.phoneB = '';
      this.seconds = '';
      this.minutes = '';
      this.sum = '';
      this.tableData = [];
      this.accountOptions = [];
      this.accountcheckOptions = [];
      this.userCalls = [];
      this.userCallsFull = [];
      this.channelOptions = [];
      this.uniqueAccountsData = {};
      this.offset = 0;
      this.limit = 50;
    },
    clearAccountFilter() {
    this.account = '';
    this.fetchData();
  },
  clearChannelFilter() {
    this.channel = '';
    this.fetchData();
  },
}
};
</script>

<style scoped>
.container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.filters, .windows, .pagination {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.filters select, .filters input[type="datetime-local"], .filters input[type="text"], .filters button {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.filters select[multiple] {
  height: 100px; 
  width: 180px; 
  overflow: auto;
  padding: 5px; 
  font-size: 14px; 
}

.filters button {
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.filters button:hover {
  background-color: #0056b3;
}

.windows {
  justify-content: space-around;
  margin-bottom: 20px;
}

.window {
  flex: 1 1 30%;
  padding: 20px;
  text-align: center;
  background-color: #f2f2f2;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.window h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
}

.window p {
  font-size: 2em;
  font-weight: bold;
  margin: 0;
}

.window .icon {
  font-size: 3em;
  color: #007bff;
  position: absolute;
  top: 10px;
  right: 10px;
}

.tables {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.table-container {
  overflow: auto;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-height: 500px;
}

.table-container p {
  margin-bottom: 10px;
}

.table-container button {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.table-container button:hover {
  background-color: #218838;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

th, td {
  width: auto;
}

.pagination {
  justify-content: center;
}

.pagination button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination button:not(:disabled):hover {
  background-color: #0056b3;
}

</style>
